.grow {
    flex-grow: 1;
}

.weight-600 {
    font-weight: 600;
}

.doable {
    color: var(--bs-success);
}

.not-doable {
    color: var(--bs-danger);
}

.btn-border {
    border: 1px solid #dee2e6;
    color: var(--bs-list-group-color);
    padding: 5px 10px;
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.hidden {
    display: none;
}

.mr-1 {
    margin-right: 1rem;
}

.no-padding {
    padding: 0;
}

.w-max {
    width: max-content;
}

.f-none {
    flex: none;
}