.vertical-grid-container {
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.popover {
  --bs-popover-max-width: 400px;
}

.horizontal-grid-container {
  display: flex;
  gap: 10px;
  flex-direction: row;
}

.footer {
  margin-top: auto;
  background-color: white;
  border: 1px solid #dee2e6;
  padding: 0.5rem 1rem;
  bottom: 0;
  position: sticky;
  width: 100%;
}

.resource-collapser {
  text-align: center;
  display: block;
  text-decoration: underline;
  color: darkblue;
  cursor: pointer;
}

.flexible-grid-container input {
  width: 100px;
}

@media (max-width: 1320px) {
  .flexible-horizontal-grid-container {
    display: flex;
    gap: 10px;
    flex-direction: column;
  }

  .flexible-grid-inner {
    flex: 1;
    min-width: calc(50% - 10px);
    padding: 5px;
    border-bottom: 2px solid #f3f3f3
  }
}

@media (min-width: 1321px) {
  .flexible-horizontal-grid-container {
    display: flex;
    gap: 10px;
    flex-direction: row;
  }

  .flexible-grid-inner {
    flex: 1;
    min-width: calc(50% - 10px);
    padding: 5px;
    border-right: 2px solid #f3f3f3
  }
}

@media (max-width: 840px) {
  .flexible-grid-container {
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr;
  }
}

@media (min-width: 841px) {
  .flexible-grid-container {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(2, 1fr);
  }
}

.header {
  align-self: center;
}

summary::marker {
  display: none;
}

.calc-select {
  align-self: center;
  height: 2rem;
  padding: 5px;
  margin-top: 5px;
  padding-top: 1px;
}

.grid-row {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  gap: 10px;
}

.grid-item {
  background-color: white;
  border: 1px solid var(--bs-border-color);
  flex: 1;
  padding: 10px;
  min-width: min-content;
}

.form-control:disabled {
  opacity: 1;
  background-color: var(--bs-secondary-bg);
  border-color: var(--bs-dark-border-subtle);
}

.grid-container-2 {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  /* Two rows of equal size */
  grid-template-columns: repeat(2, 1fr);
  /* Two columns of equal size */
  gap: 10px;
  /* Add gap between grid items (optional) */
}

.grid-container-2-col {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* Two columns of equal size */
  gap: 10px;
  /* Add gap between grid items (optional) */
}

.grid-container-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* Three columns of equal size */
  gap: 10px;
  /* Add gap between grid items (optional) */
}

.grid-inner-item {
  flex: 1;
  min-width: calc(50% - 10px);
  padding: 5px;
}

.radio-btn {
  margin-right: 5px;
}

.hidden {
  display: none;
}

.fit-border {
  width: fit-content;
}

.strength-border {
  --bs-border-color: var(--bs-danger-bg-subtle);
  --bs-body-bg: var(--bs-danger-bg-very-subtle);

  background-color: var(--bs-danger-bg-very-subtle);
  border-color: var(--bs-danger-border-subtle);
}

.strength-bg {
  background: var(--bs-danger-bg-subtle);
}

.intelligence-border {
  --bs-border-color: var(--bs-primary-bg-subtle);
  --bs-body-bg: var(--bs-primary-bg-very-subtle);

  background-color: var(--bs-primary-bg-very-subtle);
  border-color: var(--bs-primary-border-subtle);
}

.select-knight {
  text-align: center;
}

.intelligence-bg {
  background: var(--bs-primary-bg-subtle);
}

.leadership-border {
  --bs-border-color: var(--bs-success-bg-subtle);
  --bs-body-bg: var(--bs-success-bg-very-subtle);

  background-color: var(--bs-success-bg-very-subtle);
  border-color: var(--bs-success-border-subtle);
}

.leadership-bg {
  background: var(--bs-success-bg-subtle);
}

.charisma-border {
  --bs-border-color: var(--bs-secondary-bg-subtle);
  --bs-body-bg: var(--bs-secondary-bg-very-subtle);

  background-color: var(--bs-secondary-bg-very-subtle);
  border-color: var(--bs-secondary-border-subtle);
}

.maxed {
  background-color: var(--bs-gray-200);
  border-color: var(--bs-gray-500);
}

.limit-reached {
  background-color: var(--bs-warning-bg-subtle);
  border-color: var(--bs-warning-border-subtle);
}

.charisma-bg {
  background: var(--bs-secondary-bg-subtle);
}

.hidden {
  display: none;
}

.visibility-hidden {
  visibility: hidden;
}

.grow {
  flex-grow: 1;
}

.wrap {
  flex-wrap: wrap;
}

.flex {
  display: flex;
}

.margin-5 {
  margin: 5px;
}

.mt-075 {
  margin-top: 0.75rem;
}

.mb-10 {
  margin-bottom: 10px;
}

.mr-025 {
  margin-right: 0.25rem;
}

.mr-075 {
  margin-right: 0.75rem;
}

.mr-1 {
  margin-right: 1rem;
}

.mr-2 {
  margin-right: 2rem;
}

.ml-025 {
  margin-left: 0.25rem;
}

.ml-2 {
  margin-left: 2rem;
}

.pad-5 {
  padding: 5px;
}

.pad-10 {
  padding: 10px;
}

.align-flex-end {
  align-items: flex-end;
}

.lvl-border {
  border-color: #ababab
}