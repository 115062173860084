.grid-container-3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* Three columns of equal size */
    gap: 10px;
    /* Add gap between grid items (optional) */
}

.whats-this {
    align-self: flex-end;
    padding-top: 10px;
    padding-right: 50px;
}

.scroll {
    overflow: scroll;
    height: 100vh;
}

.grow {
    flex-grow: 1;
}

.text-left {
    text-align: left;
}

.ml-2 {
    margin-left: 2rem;
}

.mt-2 {
    margin-top: 2rem;
}

.padding-10 {
    padding: 10px;
}

.react-datepicker__input-container input {
    width: 6em;
}