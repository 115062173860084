main {
    height: 100vh;
    max-height: 100vh;
    overflow-x: auto;
    overflow-y: hidden;
    flex-grow: 1;
    /* width: min-content; */
}

body {
    min-height: 100vh;
    min-height: -webkit-fill-available;
    display: flex;
}

html {
    height: -webkit-fill-available;
}

hr {
    width: 100%;
}

a {
    text-align: start;
}

/* li {
    //width: 100%;
} */

ul {
    width: 100%;
}

.sidepanel {
    height: 100%;
    width: 280px;
    align-items: start;
}

.dropdown-toggle {
    outline: 0;
}

@media (min-width: 768px) {
    .bd-placeholder-img-lg {
        font-size: 3.5rem;
    }
}

.bi {
    vertical-align: -.125em;
    fill: currentColor;
}

/* Hide mobile menu by default */
.mobile-menu {
    display: none;
}

.desktop-sidebar {
    height: 100vh;
    max-height: 100vh;
    overflow-x: auto;
    overflow-y: hidden;
}