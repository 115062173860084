.grid-item {
  text-align: left;
  background-color: white;
  border: 1px solid var(--bs-border-color);
  padding: 10px;
  min-width: min-content;
}

.vertical-grid-container {
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.wrap {
  flex-wrap: wrap;
}

.padding-10 {
  padding: 10px;
}

.mt-05 {
  margin-top: 0.5rem;
}

.mb-075 {
  margin-bottom: 0.75rem;
}