/* colors */
html {
  width: 100%;
  height: 100%;
}

body {
  background-color: #eff3f4;
  position: relative;
  /*width: 100%;
  height: 100%;
  font-size: 16px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased; */
}

footer {
  position: fixed;
  bottom: 0;
  color: #747e83;
  font-size: 0.9rem;
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
}

.login {
  grid-template-columns: 1fr 1fr;
  display: grid;
  align-items: center;
  height: fit-content;
  color: #1b5c79;
  margin-top: 100px;
}

.login-intro {
  margin: 100px 50px 0 100px;
  align-self: flex-start;
}

.login-form {
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  display: block;
  width: 100%;
  max-width: 400px;
  background-color: #FFF;
  margin: 0;
  padding: 2.25em;
  box-sizing: border-box;
  border: solid 1px #DDD;
  border-radius: 0.5em;
  font-family: 'Source Sans Pro', sans-serif;
}

.login-form .svgContainer {
  position: relative;
  width: 200px;
  height: 200px;
  margin: 0 auto 1em;
  border-radius: 50%;
  pointer-events: none;
}

.login-form .svgContainer div {
  position: relative;
  width: 100%;
  height: 0;
  overflow: hidden;
  border-radius: 50%;
  padding-bottom: 100%;
}

.login-form .svgContainer .mySVG {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.login-form .svgContainer:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: inherit;
  height: inherit;
  box-sizing: border-box;
  border: solid 2.5px #217093;
  border-radius: 50%;
}

.login-form .inputGroup {
  margin: 0;
  padding: 0;
  position: relative;
}

.login-form .inputGroup:last-of-type {
  margin-bottom: 0;
}

.login-form label {
  margin: 0 0 12px;
  display: block;
  font-size: 1.25em;
  color: #217093;
  font-weight: 700;
  font-family: inherit;
  text-align: start;
}

.login-form input[type='email'],
.login-form input[type="text"],
.login-form input[type="number"],
.login-form input[type="url"],
.login-form input[type="search"],
.login-form input[type="password"] {
  display: block;
  margin: 0;
  padding: 0 1em 0;
  padding: 0.875em 1em 0;
  background-color: #f3fafd;
  border: solid 2px #217093;
  border-radius: 4px;
  -webkit-appearance: none;
  box-sizing: border-box;
  width: 100%;
  height: 65px;
  font-size: 1.55em;
  color: #1c5f7d;
  font-weight: 600;
  font-family: inherit;
  transition: box-shadow 0.2s linear, border-color 0.25s ease-out;
}

.login-form input[type='email']:focus,
.login-form input[type="text"]:focus,
.login-form input[type="number"]:focus,
.login-form input[type="url"]:focus,
.login-form input[type="search"]:focus,
.login-form input[type="password"]:focus {
  outline: none;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border: solid 2px #4eb8dd;
}

.login-form button {
  display: block;
  margin: 0;
  padding: 0.65em 1em 1em;
  background-color: #4eb8dd;
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: none;
  width: 100%;
  height: 65px;
  font-size: 1.55em;
  color: #FFF;
  font-weight: 600;
  font-family: inherit;
  transition: background-color 0.2s ease-out;
}

.login-form button:hover,
.login-form button:active {
  background-color: #217093;
}

.login-form .helper {
  position: absolute;
  z-index: 1;
  font-family: inherit;
}

.login-form .helper1 {
  top: 0;
  left: 0;
  transform: translate(1em, 2.2em) scale(1);
  transform-origin: 0 0;
  color: #217093;
  font-size: 1.55em;
  font-weight: 400;
  opacity: 0.65;
  pointer-events: none;
  transition: transform 0.2s ease-out, opacity 0.2s linear;
}

.login-form .focusWithText .helper {
  transform: translate(0.5em, 2em) scale(0.6);
  opacity: 1;
}

.login-form .inputGroup2 input[type="text"] {
  padding: 0.025em 1em 0;
}

.login-form .inputGroup2 #showPasswordToggle {
  display: block;
  padding: 0 0 0 1.45em;
  font-size: 1em;
}

.login-form .inputGroup2 #showPasswordToggle input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.login-form .inputGroup2 #showPasswordToggle .indicator {
  position: absolute;
  top: 0;
  left: 0;
  height: 1.25em;
  width: 1.25em;
  background-color: #f3fafd;
  border: solid 2px #217093;
  border-radius: 3px;
}

.login-form .inputGroup2 #showPasswordToggle .indicator:after {
  content: "";
  position: absolute;
  left: 0.25em;
  top: 0.025em;
  width: 0.5em;
  height: 0.75em;
  border: solid #217093;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
  visibility: hidden;
}

.login-form .inputGroup2 #showPasswordToggle input:checked~.indicator:after {
  visibility: visible;
}

.login-form .inputGroup2 #showPasswordToggle input:focus~.indicator,
.login-form .inputGroup2 #showPasswordToggle input:hover~.indicator {
  border-color: #4eb8dd;
}

.login-form .inputGroup2 #showPasswordToggle input:disabled~.indicator {
  opacity: 0.5;
}

.login-form .inputGroup2 #showPasswordToggle input:disabled~.indicator:after {
  visibility: hidden;
}

.login-form .inputGroup2 .toggle {
  display: block;
  padding: 0 0 0 1.45em;
  top: 0.25em;
  right: 0;
  font-size: 1em;
}

.login-form .inputGroup2 .toggle input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.login-form .inputGroup2 .toggle .indicator {
  position: absolute;
  top: 0;
  left: 0;
  height: 1.25em;
  width: 1.25em;
  background-color: #f3fafd;
  border: solid 2px #217093;
  border-radius: 3px;
}

.login-form .inputGroup2 .toggle .indicator:after {
  content: "";
  position: absolute;
  left: 0.25em;
  top: 0.025em;
  width: 0.5em;
  height: 0.75em;
  border: solid #217093;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
  visibility: hidden;
}

.login-form .inputGroup2 .toggle input:checked~.indicator:after {
  visibility: visible;
}

.login-form .inputGroup2 .toggle input:focus~.indicator,
.login-form .inputGroup2 .toggle input:hover~.indicator {
  border-color: #4eb8dd;
}

.login-form .inputGroup2 .toggle input:disabled~.indicator {
  opacity: 0.5;
}

.login-form .inputGroup2 .toggle input:disabled~.indicator:after {
  visibility: hidden;
}