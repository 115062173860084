.knight__count {
    border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
    padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
    color: var(--bs-list-group-color);
}

.modal-footer {
    border-top: none;
}

.mla {
    margin-left: auto;
}

.form-select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size="1"],
.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size="1"],
.form-control.no-validate:valid {
    border-color: var(--bs-border-color);
    background-color: var(--bs-body-bg);
    padding-right: 0.75rem;
    --bs-form-select-bg-icon: none;
}

.form-select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size="1"],
.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size="1"] {
    --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
}

.form-control.no-validate:valid {
    background-image: none;
}

.knight__count-title {
    font-size: 1rem;
    margin-bottom: 0.25rem;
}

.add-knight {
    cursor: pointer;
}

.todo-btn {
    padding: 0.25rem 0.5rem;
    padding-top: 10px;
}

.ml-025 {
    margin-left: 0.25rem;
}

.ml-1 {
    margin-left: 1rem;
}

.ml-10px {
    margin-left: 10px;
}

.mr-025 {
    margin-right: 0.25rem;
}

.mr-05 {
    margin-right: 0.5rem;
}

.mr-1 {
    margin-right: 1rem;
}

.mr-15 {
    margin-right: 1.5rem;
}

.mr-2 {
    margin-right: 2rem;
}

.mr-3 {
    margin-right: 3rem;
}

.black {
    color: black;
}

.grow {
    flex-grow: 1;
}

.wrap {
    flex-wrap: wrap;
}

.no-padding {
    padding: 0;
}

.star {
    color: #ffca00;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15)
}

.info-tile {
    display: flex;
    flex-direction: column;
    margin-right: 2rem;
}

.info-header {
    font-weight: 600;
}

.info-content {
    margin-left: 10px;
    opacity: 0.95;
}

.btn-icon {
    padding: 0.25rem 0.5rem;
}

.str-border {
    padding: 5px 10px;
    background: var(--bs-danger-bg-subtle);
}

.intel-border {
    padding: 5px 10px;
    background: var(--bs-primary-bg-subtle);
}

.lead-border {
    padding: 5px 10px;
    background: var(--bs-success-bg-subtle);
}

.char-border {
    padding: 5px 10px;
    background: var(--bs-secondary-bg-subtle);
}

.decor-border {
    padding: 5px 10px;
    background: var(--bs-dark-bg-subtle);
}

.align-self-center {
    align-self: center;
}

.scroll-y {
    overflow-y: scroll;
    max-height: 100vh;
}

.width-max {
    width: max-content;
}

.see-friends-item {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    margin-top: 5px;
}

.see-friends-buttons {
    margin-left: auto;
}